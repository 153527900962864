

export default {
  name: 'FiltersItem',
  components: {
  },
  data: () => ({
    isOpenMobile: false,
    isOpenFilter: ''
  }),
  props: {
    FilterItems: {
      type: Array,
      default() {}
    }
  }
}
